<template>
  <div class="container">
    <div class="header">
      <div class="header-content">
        <div class="header-left">
          <div><img :src="logoInfo.logo" alt=""></div>
          <div class="line"></div>
          <div class="qy-center">分时领英</div>
        </div>
        <div style="display:flex;align-items:center">
          <div class="header-nav">
            <span :class="navIndex===1?'active':''" @click="choseNav(1)">首页</span>
            <span :class="navIndex===0?'active':''" @click="choseNav(0)">用户中心</span>
          </div>
          <div class="header-right" v-if="userInfo">
            <div class="header-right-title">{{userInfo.realname.slice(0,1)}}</div>
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <div class="header-right">
                  <div class="header-right-name">{{userInfo.realname}}</div>
                </div>
              </a>
            </a-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="left">
        <div class="left-header" v-if="userInfo">
          <img v-if="userInfo.avatar" class="image" :src="userInfo.avatar" alt="">
          <img v-else class="image" src="../assets/person.png" alt="">
          <div class="enterprise-name">{{userInfo.enterpriseName}}</div>
          <div class="enterprise-tag">
            <img class="image" src="../assets/tag_01.png" alt="">
            <img class="image" src="../assets/tag_02.png" alt="">
            <!-- <img class="image" src="../assets/tag_03.png" alt=""> -->
          </div>
        </div>
        <a-menu mode="inline" :open-keys="openKeys" style="width: 280px" @openChange="onOpenChange" @click="selectIt">
          <a-sub-menu key="sub1">
            <span slot="title"><span> <img src="../assets/menu_01.png" class="img" alt="">企业信息</span></span>
            <a-menu-item key="baseInfo" :class="active==='baseInfo'?'ant-menu-item-selected':''">
              <img src="../assets/menu_03.png" class="img" alt=""> 基本信息
            </a-menu-item>
            <a-menu-item key="licenseInfo" :class="active==='licenseInfo'?'ant-menu-item-selected':''">
              <img src="../assets/menu_02.png" class="img" alt=""> 证照信息
            </a-menu-item>
            <a-menu-item key="contactInfo" :class="active==='contactInfo'?'ant-menu-item-selected':''">
              <img src="../assets/menu_04.png" class="img" alt=""> 签约信息
            </a-menu-item>
            <a-menu-item key="ticketInfo" :class="active==='ticketInfo'?'ant-menu-item-selected':''">
              <img src="../assets/ticketManage.png" class="img" alt=""> 发票信息
            </a-menu-item>
          </a-sub-menu>
          <a-menu-item :class="active==='accountInfo'?'ant-menu-item-selected':''" key="accountInfo">
            <img src="../assets/menu_05.png" class="img" alt=""> 账户安全
          </a-menu-item>
          <a-menu-item :class="active==='userInfo'?'ant-menu-item-selected':''" key="userInfo">
            <img src="../assets/menu_06.png" class="img" alt=""> 操作员管理
          </a-menu-item>
        </a-menu>
      </div>
      <div class="right">
        <div>
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ACCESS_TOKEN,USER_INFO } from "@/store/mutation-types";
import {mapActions} from 'vuex';
import Vue from 'vue';
import {
  getLoginInfo,getEnterpriseChannelConfig
} from '@/api/api';
export default {
  name: 'Layout',
  data () {
    return {
      active: 'raskManage',
      rootSubmenuKeys: ['sub1', 'sub2', 'sub4'],
      openKeys: ['sub1'],
      userInfo: null,
      navIndex: 0,
      logoInfo:{},
    }
  },
  // 刷新记住上次操作页面
  created () {
    this.getLoginInfo()
    let menu = window.sessionStorage.getItem('submenu')
    let href = window.location.href.split('?')[0];
    let ac = href.split('/');
    if (menu) {
      this.active = menu
      if (menu==='/licenseInfo') {
        this.active = 'licenseInfo'
      } else if (menu==='/baseInfo') {
        this.active = 'baseInfo'
      } else if (menu==='/contactInfo') {
        this.active = 'contactInfo'
      } else if (menu==='/ticketInfo') {
        this.active = 'ticketInfo'
      } else if (menu==='/accountInfo') {
        this.active = 'accountInfo'
      } else if (menu==='/userInfo') {
        this.active = 'userInfo'
      }
    } else {
      this.active = ac[ac.length-1]
    }
  },
  methods: {
    // 选择顶部标签
    choseNav(index) {
      this.navIndex = index
      if (index===1) {
        window.location.href = window._CONFIG['personCenterURL'] + '/#/rask'
      }
    },
    // 获取用户登录信息
    getLoginInfo () {
      getLoginInfo().then(res => {
        if (res.success) {
          this.userInfo = res.result;
          // console.log('this.userInfo',this.userInfo);
          Vue.ls.set('USER_INFO',this.userInfo)
          getEnterpriseChannelConfig(this.userInfo.taxUserId).then(res=>{
            if(res.success && res.result){
              this.logoInfo = res.result;
            }
          })
        }
      })
    },
    // submenu改变
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    // 选择submenu时跳转至相对应页面
    selectIt(value){
      window.sessionStorage.setItem('submenu',value.key)
      this.active = value.key
      if (value.key === 'licenseInfo') {
        this.$router.push('/licenseInfo')
      } else if (value.key==='baseInfo') {
        this.$router.push('/baseInfo')
      } else if (value.key==='contactInfo') {
        this.$router.push('/contactInfo')
      } else if (value.key==='ticketInfo') {
        this.$router.push('/ticketInfo')
      } else if (value.key==='accountInfo') {
        this.$router.push('/accountInfo')
      } else if (value.key==='userInfo') {
        this.$router.push('/userInfo')
      }
    },
    selectMenu (value) {
      // window.sessionStorage.setItem('submenu',value.key)
      // this.active = value.key
      // if (value.key === 'licenseInfo') {
      //   this.$router.push('/licenseInfo')
      // }
    }
  }
}
</script>
<style lang="less" scoped>
.container{
  background: #F5F7FA;
}
.header{
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border-bottom: 1px solid #E3E4E7;
  .header-content{
    width: 1270px;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    border-bottom: 1px solid #E3E4E7;
    .header-left{
      display: flex;
      align-items: center;
      img{
        // width: 52px;
        height: 36px;
        margin-right: 7px;
      }
      .name{
        font-size: 21px;
        font-family: 'FZZhengHeiS-M-GB';
        color: #202020;
      }
      .eg-name{
        font-size: 8px;
        font-family: 'FZZhengHeiS-M-GB';
        font-weight: 400;
        color: #202020;
        letter-spacing: 2px;
      }
      .line{
        width: 1px;
        height: 30px;
        background: #ABABAB;
        margin: 0 5px;
      }
      .qy-center{
        font-size: 16px;
        color: #202020;
      }
    }
    .header-right{
      display: flex;
      align-items: center;
      .header-right-title{
        width: 30px;
        height: 30px;
        background: #6379F5;
        border-radius: 50%;
        text-align: center;
        line-height: 30px;
        font-size: 16px;
        color: #FFFFFF;
        margin-right: 12px;
      }
      .header-right-name{
        // width: 143px;
        color: #202020;
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
.header-nav{
  display: flex;
  align-items: center;
  margin: 0 128px 0 0;
  span{
    color: #202020;
    font-size: 14px;
    box-sizing: border-box;
    font-weight: 400;
    height: 64px;
    line-height: 64px;
    margin-left: 54px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
  }
  .active{
    // font-weight: bold;
    color: #000000;
    border-bottom: 2px solid #2878FF;
    font-size: 14px;
  }
}
.left-header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #434951;
  font-size: 18px;
  .image{
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
  .enterprise-name{
    margin:23px 0 14px 0;
    width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
  }
  .enterprise-tag{
    display: flex;
    align-items: center;
    margin: 0 0 20px 0;
    .image{
      width: 22px;
      height: 20px;
      margin: 0 10px 0 0;
      border-radius: 0;
      &:last-child{
        margin: 0;
      }
    }
  }
}
.img{
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.content{
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 40px 0 40px 0;
  .left{
    box-sizing: border-box;
    width: 280px;
    height: 969px;
    padding: 40px 0;
    // border-right: 1px solid #E3E4E7;
    margin-right: 10px;
    background: #FFFFFF;
    box-shadow: 0px 6px 19px 1px rgba(40, 120, 255, 0.1), 0px 16px 24px 0px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    ul{
      padding: 0;
      list-style: none;
      li{
        border-left: 3px solid transparent;
        width: 280px;
        box-sizing: border-box;
        padding: 15px 0 15px 47px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
      }
      cursor: pointer;
      .active{
        background: #E6F7FF;
        border-left: 3px solid #2878FF;
        color: rgba(40, 120, 255, 0.85);
      }
    }
  }
  .right{
    box-sizing: border-box;
    width: 980px;
    // height: 969px;
    min-height: 969px;
    background: #FFFFFF;
    padding: 50px 40px;
    box-shadow: 0px 6px 19px 1px rgba(40, 120, 255, 0.1), 0px 16px 24px 0px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
  }
}
.content .left ul .ant-menu-item{
  padding: 0 0 0 24px;
}
.content .left ul .ant-menu-submenu{
  padding: 0 1px 0 0px;
}
.content .left ul .ant-menu-submenu .ant-menu-item{
  width: 280px;
  margin-left: -3px;
}
.content .left ul .ant-menu-item-selected{
  border-left: 3px solid #2878FF;
  color: rgba(0, 0, 0, 0.85);
  border-right: 0;
  background: #EDEFF3;
  &:after{
    display: none;
  }
}
</style>
<style>
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow{
  display: none !important;
}
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow{
  display: none;
}
.content .left ul{
  border-right: 0;
}
</style>